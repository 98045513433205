import React from 'react';

const MapSection = () => (
  <section className="map" id="contact">
    <iframe
      title="where-we-are-located"
      width="100%"
      height="100%"
      frameBorder="0"
      scrolling="no"
      marginHeight="0"
      marginWidth="0"
      src="https://www.google.com/maps/embed/v1/place?q=%D0%BA%D0%B8%D0%B5%D0%B2%D1%81%D0%BA%D0%B8%D0%B9%20%D0%B2%D0%B5%D0%BB%D0%BE%D1%82%D1%80%D0%B5%D0%BA&key=AIzaSyAUIJSA-GdKsZHU92Tf_I17AQyfvA12YPw"
    />
    <br />
  </section>
);
export default MapSection;
