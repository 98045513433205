import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';
import Layout from '../components/layout';
import { FaFacebook, FaInstagram, FaPhone } from 'react-icons/fa';
import MapSection from '../about/components/map';
import '../css/contacts.css';

class Contacts extends Component {
  render() {
    return (
      <div>
        <div className="jumbotron" style={{ marginBottom: 0 }}>
          <h2>Дмитро Воловод</h2>
          <FaInstagram />
          <a href="https://www.instagram.com/volovod_dmytro/" style={{ color: 'black' }}>
            {' '}
            Instagram
          </a>
          <hr />
          <h2>Должиков Гліб </h2>
          <FaPhone />
          <a href="tel:+380509276677" style={{ color: 'black' }}>
            {' '}
            0509276677
          </a>
          <br />
          <FaInstagram />
          <a href="https://www.instagram.com/glebdolzhikov" style={{ color: 'black' }}>
            {' '}
            Instagram
          </a>
        </div>
        <MapSection />
      </div>
    );
  }
}

const LandingPageWithFirebase = withFirebase(withTrainings(Contacts));

export default () => (
  <Layout>
    <LandingPageWithFirebase />
  </Layout>
);
